<template>
  <v-layout row wrap class="max-width-1400 full-width ma-auto">
    <v-flex xs12 py-5 text-xs-center>
      <p class="graphik-medium mb-4 secondary--text px-4" :class="{'font-28': $vuetify.breakpoint.smAndUp, 'font-20': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_most_quoted_cars_title') }}</p>

      <p class="graphik-light max-width-900 mb-5 pb-5 mx-auto px-4" :class="{'font-18': $vuetify.breakpoint.smAndUp, 'font-16': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_most_quoted_cars_description') }}</p>

      <v-card flat height="100%" color="transparent">
        <v-card-title class="pa-0 pb-5">
          <v-layout row wrap align-center>
            <v-flex xs12 sm4 px-3 pb-5 v-for="(car, index) in cars" :key="index">
              <v-card flat>
                <v-img
                  :aspect-ratio="16/9"
                  contain
                  class="ma-auto background mb-0"
                  position="center center"
                  :src="car.image"
                  :lazy-src="car.image"
                  max-height="110px"
                ></v-img>

                <v-card-text class="pa-0 light-background py-3">
                  <p class="graphik-bold dark-grey--text my-3" :class="{'font-20': $vuetify.breakpoint.smAndUp, 'font-18': $vuetify.breakpoint.xsOnly}">{{ car.car }}</p>

                  <p class="graphik-light font-16 mb-0">{{ $ml.get('general_from') }}</p>

                  <p class="graphik-medium font-20 mb-0 dark-grey--text">{{ car.price | price }}</p>

                  <p class="graphik-light font-16">{{ $ml.get('quote_result_annual') }}</p>

                  <p class="graphik-light font-13 primary--text">*{{ $ml.get('fields_coverage') }} {{ $ml.get('fields_amplia') }}*</p>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-title>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'ContentMostQuotedCars',
  data () {
    return {
      cars: [
        {
          car: 'Nissan March Sense 2016 STD',
          price: 6080,
          image: require('@/assets/img/images/march.png')
        },
        {
          car: 'Nissan Versa 2014',
          price: 7109,
          image: require('@/assets/img/images/versa.png')
        },
        {
          car: 'Honda CR-V EX 2015',
          price: 8611,
          image: require('@/assets/img/images/crv.png')
        }
      ]
    }
  }
}
</script>

<style scoped>
  .background {
    background: linear-gradient(180deg, white 50%, #FAFAFA 50%);
  }
</style>